/*
color tips:
#191919 - background
var(--color-accent) - highlight
#ffc210 - yellow
#f57722 - orange
#f8064d - pink
*/

/*-----------------------------------------
================= APP =====================
------------------------------------------*/
:root {
  --color-black: #000000;
  --color-white: #ffffff;
  --color-grey: #ccc;
  --color-accent: #f9004d;
  --color-accent2: #fc004d;
  --color-elegant-black: #191919;
  --color-grey-text: #C6C9D8BF;
  --color-title-text: #1f1f25;
  --color-darkgrey: #4B515D;
  --bgcolor-aboutwrapper-light: #ebecf0;
  background-color: #000000;
}

* {
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
}

body {
  overflow: auto;
  font-size: 14px;
  line-height: 1.71429;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: hsl(240, 11%, 13%);
}

a {
  text-decoration: none !important;
}


/*------------------------------------------
 ================= NAVBAR ==================
 ------------------------------------------*/
#home h6 {
  line-height: 1.7;
}

#leftbar {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  list-style: none;
  align-items: center;
}

#leftbar li {
  margin: 0 15px;
  position: relative;
}

.navbar-wrap {
  box-shadow: none;
}

.alink {
  position:relative;
  color: var(--color-white) !important;
  font-size: 16px;
  font-weight: 500;
  padding: 20px 0;
  display: inline-block;
}

.alink-light {
  position:relative;
  color: var(--color-black);
  font-size: 16px;
  font-weight: 500;
  padding: 20px 0;
  display: inline-block;
}

.bg_image {
  background-image: url(./assets/bg-image-1.jpg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.current {
  position:relative;
  font-size: 16px;
  font-weight: 500;
  padding: 20px 0;
  display: inline-block;
  color:rgba(249,0,77, 0.9) !important;
}

.current::after {
  position: absolute;
  content: "";
  left: 0%;
  top: 98%;
  height: 5px;
  width: 100%;
  background: var(--color-accent);
  transition: .3s;
  bottom: 0;
}

.dark {
  background-color: #101010;
}

.inner {
  padding-top: 50px;
}

.inner-span {
  color: var(--color-grey-text);
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 4px;
  display: block;
  margin-bottom: 20px;
}

.dark-text {
  color: var(--color-black);
}

.filler-dis {
  visibility: hidden;
}

.filler-vis {
  visibility: visible;
  height: 82px;
  background-color: var(--color-white);
}

.nav-logo-dark {
  width: 45px;
  height: 45px;
  border-radius: 50%;
}

.nav-logo-light {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  opacity: 0.9;
}

.rightnav {
  flex-direction: row;
  align-items: center;
}

.role {
  color: var(--color-accent)
}

.social-icon {
  margin: 10px;
  color: var(--color-white);
}

.slider-wrapper {
  color: var(--color-white);
}

.slide {
  height: auto;
  padding-top: 150px;
  padding-bottom: 150px;
  position: relative;
}

.title2,
.title2 div,
.inner h2 {
  font-weight: 900;
  width: 85%;
  font-size: 54px;
  line-height: 78px;
  font-family:  'Montserrat', sans-serif;
}

.social-cont {
  color: var(--color-white);
}

.social-cont-ul {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.social-cont-li-a {
  margin: 0 10px;
  width: 30px;
  height: 30px;
  line-height: 44px;
  font-size: 14px;
  display: inline-block;
  color: var(--color-white);
  text-align: center;
  transition: .3s ease all;
  display: flex;
  justify-content: center;
  align-items: center;
}

.social-cont-li-a:hover {
  color: var(--color-accent);
  transform: translateY(-0.15em);
  transition: .3s ease all;
}

.color--black {
  color: var(--color-black);
}

/* TOGGLER SWITCH */

.theme-switch-wrapper {
  display: flex;
  align-items: center;
}

.theme-switch {
  display: inline-block;
  height: 24px;
  position: relative;
  width: 50px;
  margin: 5px;
}

.theme-switch input {
  display:none;
}

.slider {
  background-color: var(--color-grey);
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: .4s;
}

.slider:before {
  background-color: var(--color-black);
  bottom: 3px;
  content: "";
  height: 18px;
  left: 4px;
  position: absolute;
  transition: .4s;
  width: 18px;
}

input:checked + .slider {
  background-color: var(--color-elegant-black);
}

input:checked + .slider:before {
  transform: translateX(24px);
  background-color: var(--color-white);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/*-----------------------------------------
================= ABOUT ===================
------------------------------------------*/
.about-img-dark {
  border-radius: 5px;
  opacity: 0.8;
}

.about-img-light {
  border-radius: 5px;
}

.shade {
  box-shadow: 2px 5px 15px -4px rgba(0,0,0,0.63);
  -webkit-box-shadow: 2px 5px 15px -4px rgba(0,0,0,0.63);
}

.shade:hover {
  box-shadow: 3px 6px 15px -4px rgba(0,0,0,0.63);
  -webkit-box-shadow: 3px 6px 15px -4px rgba(0,0,0,0.63);
}

.about-wrapper-dark {
  background-color: var(--color-elegant-black);
  height: auto;
}

.about-wrapper-light {
  background-color: var(--bgcolor-aboutwrapper-light);
}

.description-dark {
  color: var(--color-grey-text);
  line-height: 30px;
  font-size: 1.2em;
}

.description-light {
  color: var(--color-darkgrey);
  line-height: 30px;
  font-size: 1.2em;
}

.title-dark {
  font-size: 3.1em;
  font-weight: 700;
  font-family: 'Poppins', sans-serif;
  color: var(--color-white);
}

.title-light {
  font-size: 3.1em;
  font-weight: 700;
  font-family: 'Poppins', sans-serif;
  color: var(--color-title-text);
  text-shadow: 0.5px 0.5px 1px grey;
}

/*----------------------------------------
================ PORTFOLIO ===============
-----------------------------------------*/
img {
  max-width: 100%;
}

.portfolio-wrapper-light {
  background-color: var(--color-white);
}

.project-content {
  position: absolute;
  bottom: -92px;
  width: 100%;
  padding: 50px 35px;
  z-index: 3;
  transition: .3s ease all;
}

.project-content:before {
  position: absolute;
  content: "";
  background: linear-gradient(180deg,transparent 0,var(--color-black) 70%);
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  z-index: -1;
  cursor: pointer;
}

.projecttype {
  font-size: 14px;
  margin-bottom: 5px;
  color: #c6c9d8;
  line-height: 30px;
  cursor: pointer;
}

.project-style--1 {
  position: relative;
  margin-bottom: 40px;
  overflow: hidden;
  border-radius: 6px;
  cursor: pointer;
}

.project-style--1:hover img {
  transform:scale(1.2);
}

.project-style--1:hover .thumbnail-a:after {
  opacity: 0.85
}

.project-style--1:hover .project-content {
  bottom: -20px;
  transition: .3s ease all;
}

.project-style--1:hover .project-btn {
  opacity: 1;
  visibility: visible;
}

.project-btn {
  margin-top: 25px;
  transition: .7s;
  opacity: 0;
  visibility: hidden;
}

.portfolio-wrapper-dark {
  background-color: #101010;
}


.quote-dark {
  padding: 10px 0px;
  font-size: 18px;
  line-height: 30px;
  font-weight: 300;
  color: var(--color-grey-text);
  margin: 0px;
  font-style: italic;
}

.quote-light {
  padding: 10px 0px;
  font-size: 18px;
  line-height: 30px;
  font-weight: 400;
  color: var(--color-darkgrey);
  margin: 0px;
  font-style: italic;
}
.rn-btn-style-1 {
  color: var(--color-white);
  padding: 0 13px;
  margin-right: 10px;
  height: 40px;
  display: inline-block;
  line-height: 34px;
  border: 2px solid var(--color-accent);
  border-radius: 4px;
  font-size: 14px;
  position: relative;
  letter-spacing: .2px;
  text-transform: uppercase;
  transition: .3s ease all;
  z-index: 2;
}

.rn-btn-style-1:hover {
  color: var(--color-white);
  background-color: var(--color-accent);
  transform: translateY(-0.2em);
}

.rn-btn-style-2-dark {
  border: 2px solid var(--color-accent);
  background-color: var(--color-accent);
  color: var(--color-white);
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 2px;
  padding: 15px 40px;
  border-radius: 6px;
  display: inline-block;
  font-weight: 500;
  outline: none;
  transition: .3s ease all;
}

.rn-btn-style-2-dark:focus {
  outline: none;
  box-shadow: none;
}

.rn-btn-style-2-dark:hover {
  color: var(--color-white);
  /* color: black; */
  background-color: transparent;
  transform: translateY(-0.2em);
  transition: .3s ease all;
}

.rn-btn-style-2-light {
  border: 2px solid var(--color-accent);
  background-color: var(--color-accent);
  color: var(--color-white);
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 2px;
  padding: 15px 40px;
  border-radius: 6px;
  display: inline-block;
  font-weight: 500;
  outline: none;
  transition: .3s ease all;
}

.rn-btn-style-2-light:focus {
  outline: none;
  box-shadow: none;
}

.rn-btn-style-2-light:hover {
  color: var(--color-black);
  background-color: transparent;
  transform: translateY(-0.2em);
  transition: .3s ease all;
}

.thumbnail-a {
  display: block;
  position: relative;
  z-index: 1;
}

.thumbnail-a:after {
  background: linear-gradient(180deg,var(--color-accent2) 0,var(--color-black));
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  content: "";
  z-index: 1;
  opacity: 0;
  transition: 0.5s ease all;
}

.thumbnail img {
  border-radius: 5px;
  box-shadow: 0 25px 65px rgb(0 0 0 / 10%);
  transition: transform .5s ease;
}

.thumb-title {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 20px;
  cursor: pointer;
}

.thumb-title a,
.thumb-title a:hover  {
  color: var(--color-white);
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
}

.w-100 {
  width: 100%;
}

/*-----------------------------------------
================= CONTACTS ================
------------------------------------------*/
.textarea-dark {
  display: block;
  width: 100%;
  margin-bottom: 20px;
  line-height: 46px;
  transition: all .3s ease;
  min-height: 60px;
  font-size: 15px;
  letter-spacing: .1px;
  padding: 10px 20px;
  border-radius: 5px;
  outline: none;
  background-color: var(--color-elegant-black);
  border: 2px solid hsla(0,0%,100%,.2);
  color:rgba(198,201,216,.75);
}

.textarea-light {
  display: block;
  width: 100%;
  margin-bottom: 20px;
  line-height: 46px;
  transition: all .3s ease;
  min-height: 60px;
  font-size: 15px;
  letter-spacing: .1px;
  padding: 10px 20px;
  border-radius: 5px;
  outline:none;
  background-color:hsla(0,0%,100%,.2);
  border: 2px solid var(--color-darkgrey);
  color:#212121;
}

label {
  width: 100%;
}

.contacts-wrapper-dark {
  background-color: var(--color-elegant-black);
}

.contacts-wrapper-light {
  background-color: var(--bgcolor-aboutwrapper-light);
}

.contact-form-dark input {
  display: block;
  width: 100%;
  margin-bottom: 25px;
  line-height: 46px;
  transition: all .3s ease;
  height: 50px;
  font-size: 15px;
  letter-spacing: .1px;
  padding: 0 20px;
  border-radius: 5px;
  outline:none;
  background-color: var(--color-elegant-black);
  border: 2px solid var(--color-darkgrey);
  color:rgba(198,201,216,.75);
}

.contact-form-light input {
  display: block;
  width: 100%;
  margin-bottom: 25px;
  line-height: 46px;
  transition: all .3s ease;
  height: 50px;
  font-size: 15px;
  letter-spacing: .1px;
  padding: 0 20px;
  border-radius: 5px;
  outline:none;
  background-color: hsla(0,0%,100%,.2);
  border: 2px solid var(--color-elegant-black);
  color:#212121;
}

.contacts-img {
  border-radius: 5px;
}

.check_vis {
  color: green;
  margin-right: 7px;
}

.inline-a-dark {
  color: var(--color-white);
  font-weight: 600;
}

.inline-a-light {
  color: var(--color-darkgrey);
  font-weight: 600;
}

.inline-a:hover {
  color: var(--color-accent2);
}

.msg_vis-dark {
  min-height: 24px;
  color: var(--color-white);
  padding-top: 10px;


}

.msg_vis-light {
  min-height: 24px;
  padding-top: 10px;
}

.msg_dis-dark {
  min-height: 24px;
  color: var(--color-elegant-black);
  margin-top: 10px;
}

.msg_dis-light {
  min-height: 24px;
  color: var(--bgcolor-aboutwrapper-light);
  margin-top: 10px;
}

.msg-dark {
  color: var(--color-white);
}

.msg-light {
  color: #212121;
}

.submit-btn {
  min-width: 50%;
  margin-bottom: 15px;
}

/*-----------------------------------------
================= TABS ====================
------------------------------------------*/
.degree {
  font-size: 16px;
  font-weight: 600;
}

#tabs-choice-dark {
  color: var(--color-white);
}

#tabs-choice-light {
  color: #1d1d24;
}

.selected,
.selected:hover,
.selected:focus,
.selected:active {
  position: relative;
  display: inline-block;
  font-size: 1.2em;
  font-weight: 600;
  text-align: center;
}

.not-selected,
.not-selected:hover,
.not-selected:focus,
.not-selected:active {
  position: relative;
  display: inline-block;
  font-size: 1.2em;
  font-weight: 600;
  text-align: center;
}

.not-selected::after {
  content: "";
  position: absolute;
  top: 105%;
  height: 3px;
  width: 30px;
  left: 0%;
  background-color: #717173;
  transition: 0.5s ease all;
}

.selected::after {
  content: "";
  position: absolute;
  top: 105%;
  height: 3px;
  width: 100%;
  left: 0%;
  background-color: rgba(249,0,77, 0.9);
  transition: 0.2s ease all;
}

.single-tab-content-dark {
  height: 250px;
  padding-top: 1em;
  color: var(--color-grey-text);
  font-size: 16px;
  font-weight: normal;
}

.single-tab-content-light {
  height: 250px;
  padding-top: 1em;
  color: var(--color-darkgrey);
  font-size: 16px;
  font-weight: normal;
}

/*-----------------------------------------
================ FOOTER ===================
------------------------------------------*/
ul {
  list-style-type: none;
}

.footer-wrapper {
  background-color: var(--color-black);
}

.footer-inner-p {
  color: rgba(198,201,216,.75);
}

.social-share li {
  padding-top: 10px;
}

.social-share-li-a {
  margin: 0 10px;
  width: 50px;
  height: 50px;
  line-height: 44px;
  font-size: 16px;
  display: inline-block;
  border: 2px solid rgba(198,201,216,.75);
  color: rgba(198,201,216,.75);
  border-radius: 100%;
  text-align: center;
  transition: .3s ease all;
  display: flex;
  justify-content: center;
  align-items: center;
}

.social-share li a:hover {
  background-color: var(--color-accent2);
  border: 2px solid var(--color-accent2);
  color: var(--color-white);
  transform: translateY(-0.15em);
  transition: .3s ease all;
}

.logo-text {
  width: 146px;
  height: 53px;
}

/*-----------------------------------------
=============== BACK-TO-TOP ===============
------------------------------------------*/
.arrow {
  color: var(--color-grey-text);
  vertical-align: middle;
}

.back-to-top {
  background-color: var(--color-elegant-black);
}

.totop-cont {
  position: fixed;
  bottom: 30px;
  right: 20px;
  cursor: pointer;
  opacity: 0.3;
  visibility: visible;
  background-color: var(--color-black);
  z-index:999;
  width: 50px;
  height: 50px;
  line-height: 49px;
  border-radius: 50%;
  text-align: center;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%);
  transition: opacity 0.2s linear 0s;
}

.totop-cont:hover {
  opacity: 0.8;
}


/*-----------------------------------------
================ RESPONSIVE ===============
------------------------------------------*/
@media (min-width: 0px) {
  .bg_image {
    max-height: 40vh;
  }
  .title2,
  .title2 div,
  .inner h2 {
    font-weight: 900;
    font-size: 20px;
    line-height: 20px;
    width: 90%;
  }
  .inner span {
    font-size: 12px;
    margin-bottom: 10px;
  }
  .rn-btn-style-1 {
    padding: 0 5px;
    margin-right: 4px;
  }
  .navbar-brand {
    display: none;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .bg_image {
    max-height: 50vh;
  }
  .title2,
  .title2 div,
  .inner h2 {
    font-weight: 900;
    font-size: 34px;
    line-height: 40px;
    width: 90%;
  }
  .inner span {
    font-size: 15px;
    margin-bottom: 20px;
  }
  .rn-btn-style-1 {
    padding: 0 12px;
    margin-right: 10px;
  }

  .navbar-brand {
    display: none;
  }

}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .bg_image {
    max-height: 80vh;
  }
  .title2,
  .title2 div,
  .inner h2 {
    font-size: 44px;
    line-height: 78px;
    width: 85%;
  }
  .inner span {
    font-size: 15px;
    margin-bottom: 20px;
  }
  .rn-btn-style-1 {
    padding: 0 12px;
    margin-right: 10px;
  }

  .navbar-brand {
    display: block;
  }

}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .bg_image {
    max-height: 100vh;
  }
  .title2,
  .title2 div,
  .inner h2 {
    font-weight: 900;
    width: 85%;
    font-size: 54px;
    line-height: 78px;
  }
  .inner span {
    font-size: 15px;
    margin-bottom: 20px;
  }
  .rn-btn-style-1 {
    padding: 0 6px;
    margin-right: 5px;
  }
  .navbar-brand {
    display: block;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .bg_image {
    min-height: 100vh;
  }
  .rn-btn-style-1 {
    padding: 0 12px;
    margin-right: 10px;
  }
  .navbar-brand {
    display: block;
  }
}

@media (max-width: 440px) {
  .about-wrapper-light {
    height: 1400px;
  }

  .about-wrapper-dark {
    height: 1400px;
  }
}


